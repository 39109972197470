import { styled } from 'styled-components';
import {
  Box,
  FormControl,
  Grid,
  Drawer,
  Typography,
  Icon,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { ICv, IIdioma } from '../../../../commons/genericTypes';
import {
  ButtonStato,
  IconDrawer,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import InputArquivo from '../../../../components/InputArquivo/InputArquivo';
import { useCvActions } from '../../../../states/crm/contatoAuxiliares/cvs/cv.actions';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import ButtonAdd from '../ButtonAdd';

const empty: ICv = {
  id: 0,
  nome: '',
  idioma: '',
  // arquivo: '',
  // dtCadastro: '',
  // excluido: '',
};

interface Props {
  novoUsuario: boolean;
}

const CurriculoComponent: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  const auth = useRecoilValue(authAtom);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const cvsWatch: ICv[] = methods.watch('cv');
  const id: number = methods.watch('id');
  const [cvs, setCVs] = useState<ICv[]>([]);
  const [cv, setCV] = useState<ICv>();
  const [selectedCVs, setSelectedCVs] = useState<ICv[] | undefined>(undefined);
  const [idioma, setIdioma] = useState<IIdioma>({});

  const [editAcao, setEditAcao] = useState<boolean>(false);
  const [acaoEmEdicao, setAcaoEmEdicao] = useState<ICv>(empty);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [acaoDeleteId, setAcaoDeleteId] = useState<number>(0);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  const [idiomasSelect, setIdiomasSelect] = useState<IIdioma[]>([]);
  const idiomaConfigActions = useIdiomaConfigActions();


  const cvActions = useCvActions();


  const columns: GridColDef[] = [
    {
      field: 'nomeCv',
      headerName: 'Nome',
      width: 190,
    },
    {
      field: 'idioma',
      headerName: 'Idioma',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return idiomasSelect?.find((f) => f.id === obj.idIdioma)?.nome;
      },
    },
    // {
    //   field: 'arquivo',
    //   headerName: 'Arquivo',
    //   width: 150,
    // },
    {
      field: 'dtCadastro',
      headerName: 'Dt Cadastro',
      width: 150,
    },
    {
      field: 'excluido',
      headerName: 'Excluído',
      width: 150,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return (
          <Icon
            sx={{ cursor: 'pointer' }}
            onClick={() => HandleApagar(obj.id!)}
          >
            {' '}
            <DeleteIcon />{' '}
          </Icon>
        );
      },
    },
  ];

  const HandleAtivoInativo = (usuario: any) => {
    usuario.ativo = usuario.ativo ? false : true;
    AtualizarUsuario(usuario);
    atualizarFormulario(empty);
  };

  const AtualizarUsuario = (data: any) => { };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.id > 0) AtualizarUsuario(data);
    else setLoadingSave(false);
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    idiomaConfigActions
      .list()
      .then((resp: IIdioma[]) => setIdiomasSelect(resp))
      .catch((err: any) => toast.warn(err));

    if (cvsWatch?.length > 0) setCVs(cvsWatch);

    // enderecoActions
    //   .list(false, `?contatoId=${id}`)
    //   .then(data => setEnderecos(data))

    setLoading(false);
  }, []);

  useEffect(() => {
    if (id) {
      cvActions
        .list(false, `?contatoId=${id}`)
        .then((resp: ICv[]) => setCVs(resp))
        .catch((err: any) => toast.warn(err));

      setLoading(false);
    }

  }, [id]);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const openDrawerCV = (result: any) => {
    setCV(empty);
    setOpen(result);
  };

  const HandleApagar = (id: any) => {
    const sub = cvs.filter((f) => f.id !== id);
    setCVs(sub);
    // methods.setValue('cv', cvs!);
  };

  const AdicionarNaLista = () => {
    // cv!.arquivo = arquivoUrl;
    cv!.nome = nomeArquivo;
    cv!.idioma = idioma.nome;
    cv!.idIdioma = idioma.id;
    cv!.nomeCv = nomeArquivo;
    // cv!.dtCadastro = Date.now().toLocaleString();

    if ((!cv?.idContato || cv?.idContato <= 0) && Number(id) > 0) {
      cv!.idContato = Number(id);
    }
    cvActions.createFormData(cv!).then(data => {
      const newList = cvs.concat(cv!);
      setCVs(newList);
      methods.setValue('cv', newList);
      setIdioma({});
      setArquivoUrl("");
      setNomeArquivo("");
      openDrawerCV(false);
    })
    setLoadingSave(false);
  };

  const HandleSetIdioma = (idioma: any) => {
    let id = idioma.target.value;
    let idiomaI = idiomasSelect.find((f) => f.id == id);
    setIdioma(idiomaI!);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => row?.id}
              onRowClick={handleRowClick}
              rows={cvs ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <ButtonAdd onClick={() => openDrawerCV(true)} />

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerCV(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerCV(false)}>
                    close
                  </IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant='h5'>
                    {'Adicionar CV na Lista'}
                  </Typography>
                </Grid>
              </Grid>

              <FormControl required fullWidth variant='outlined' sx={{ marginTop: "20px" }}>
                <InputLabel id='demo-simple-select-label'>
                  Idioma
                </InputLabel>
                <SelectStato onChange={HandleSetIdioma} required label='Consultor'>
                  {idiomasSelect &&
                    idiomasSelect!.map(
                      (grau: IIdioma, index: number) => (
                        <MenuItem value={grau.id} key={index}>
                          {grau.nome}
                        </MenuItem>
                      )
                    )}
                </SelectStato>
              </FormControl>

              <InputArquivo
                isEdit={editAcao}
                objOnEdit={acaoEmEdicao}
                setObjOnEdit={setAcaoEmEdicao}
                newObj={cv}
                setNewObj={setCV}
                nameFile={nomeArquivo}
                setNameFile={setNomeArquivo}
                urlFile={arquivoUrl}
                setUrlFile={setArquivoUrl}
              />

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant='contained' onClick={AdicionarNaLista}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default CurriculoComponent;
