import {
  Box,
  FormControl,
  Grid,
  Drawer,
  Typography,
  Icon,
  LinearProgress,
  InputLabel,
  MenuItem,
} from '@mui/material';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import {
  IComandaEtapaReunioes,
  IConsultor,
  IEtapa,
  IReuniao,
  IUsuario,
} from '../../../../../commons/genericTypes';
import {
  ButtonStato,
  IconDrawer,
  SelectStato,
  TextFieldStato,
} from '../../../../../commons/styleds';
import { authAtom } from '../../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../../helpers/history';
//import { useReuniaoActions } from '../../../../../states/crm/contatoAuxiliares/reunioes/reuniao.actions';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useUsuarioActions } from '../../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { useComandaEtapaReuniaoActions } from '../../../../../states/config/Programas/comandaEtapaReunioes/comandaEtapaReuniao.actions';
import { possuiPermissao } from '../../../../../commons/validaPermissao';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useConsultorActions } from '../../../../../states/config/CadastroUsuarios/consultores/consultor.actions';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const empty: IReuniao = {
  id: 0,
  tbEtapaId: 0,
  onboarding: false,
  dtRealizadaInicio: undefined,
  dtRealizadaFim: undefined,
  idConsultorResponsavel: 0,
  valorHora: undefined,
  tempoTotal: '',
  valorTotal: '',
};

interface IParams {
  idComanda?: number;
  etapas?: IEtapa[];
  comandaEtapas?: IComandaEtapaReunioes[];
}

const ReuniaoComponent: React.FC<IParams> = (props) => {
  const { idComanda, etapas, comandaEtapas } = props;
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [editando, setEditando] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [etapasSelect, setEtapasSelect] = useState<IEtapa[]>([]);
  const [reuniao, setReuniao] = useState<IComandaEtapaReunioes>(empty);

  const [valorHora, setValorHora] = useState<string>();

  const consultorActions = useConsultorActions();
  const [consultores, setConsultores] = useState<IConsultor[]>([]);
  const comandaEtapaActions = useComandaEtapaReuniaoActions();

  const columns: GridColDef[] = [
    {
      field: 'idEtapa',
      headerName: 'Reunião',
      width: 200,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return etapas?.find((f) => f.id === obj.tbEtapaId)?.nome;
      },
    },
    {
      field: 'dtPrevista',
      headerName: 'Data Prevista',
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return new Date(obj.dtPrevista!).toLocaleDateString();
      },
    },
    {
      field: 'dtRealizadaInicio',
      headerName: 'Hora Início',
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return new Date(obj.dtRealizadaInicio!).toLocaleTimeString();
      },
    },
    {
      field: 'dtRealizadaFim',
      headerName: 'Hora Fim',
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return new Date(obj.dtRealizadaFim!).toLocaleTimeString();
      },
    },
    {
      field: 'idConsultorResponsavel',
      headerName: 'Consultor (a):',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return consultores?.find((f) => f.id === obj.idConsultorResponsavel)
          ?.nome;
      },
    },
    {
      field: 'valorHora',
      headerName: 'Valor Hora',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return (
          <>
            {' '}
            {obj.valorHora
              ? 'R$ ' + parseFloat(obj.valorHora!.toString()).toFixed(2)
              : ''}{' '}
          </>
        );
      },
    },
    {
      field: 'tempoTotal',
      headerName: 'Tempo Total',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return getTempoTotal(
          new Date(obj.dtRealizadaInicio!.toString()).toLocaleTimeString(),
          new Date(obj.dtRealizadaFim!.toString()).toLocaleTimeString()
        );
      },
    },
    {
      field: 'valorTotal',
      headerName: 'Valor Total',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return getValorTotal(
          new Date(obj.dtRealizadaInicio!.toString()).toLocaleTimeString(),
          new Date(obj.dtRealizadaFim!.toString()).toLocaleTimeString(),
          obj.valorHora!.toString()
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IReuniao;
        return (
          <Icon
            sx={{ cursor: 'pointer' }}
            onClick={() => HandleApagar(obj.id!)}
          >
            {' '}
            <DeleteIcon />{' '}
          </Icon>
        );
      },
    },
  ];

  const getTempoTotal = (horaInicio: string, horaFim: string) => {
    let horaTotal = '';
    // duração de uma jornada normal de trabalho (em minutos)
    let hInicio = parse(horaInicio);
    // quantidade de minutos efetivamente trabalhados
    let hFim = parse(horaFim);

    // diferença entre as jornadas
    let diff = Math.abs(hFim - hInicio);
    if (diff !== 0) {
      let horas = Math.floor(diff / 60);
      let minutos = diff - horas * 60;
      horaTotal = horas + ':' + (minutos > 9 ? minutos : '0' + minutos);
    }

    return horaTotal;
  };

  const getValorTotal = (
    horaInicio: string,
    horaFim: string,
    valorHora: string
  ) => {
    let valorTotal = '';

    // duração de uma jornada normal de trabalho (em minutos)
    let hInicio = parse(horaInicio);
    // quantidade de minutos efetivamente trabalhados
    let hFim = parse(horaFim);

    // diferença entre as jornadas
    let diff = Math.abs(hFim - hInicio);
    if (diff !== 0) {
      let hValor = valorHora === '' ? '0' : valorHora;
      let valor = parseFloat(hValor) * (diff / 60);

      valorTotal = 'R$ ' + valor.toFixed(2);
    }

    return valorTotal;
  };

  function parse(horario: any) {
    // divide a string em duas partes, separado por dois-pontos, e transforma em número
    let [hora, minuto] = horario.split(':').map((v: any) => parseInt(v));
    if (!minuto) {
      // para o caso de não ter os minutos
      minuto = 0;
    }
    return minuto + hora * 60;
  }

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    const comandaEtapaList = comandaEtapas?.filter(
      (f) => f.idConsultorResponsavel! > 0 && !f.onboarding
    );
    setReunioes(comandaEtapaList!);

    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== 'onboarding'
    )!;
    const llistEtapas = listEtapas?.filter(
      (f) =>
        !comandaEtapaList?.some(
          (s) => s.tbEtapaId === f.id && s.idConsultorResponsavel! > 0
        )
    );
    setEtapasSelect(llistEtapas);

    consultorActions
      .list()
      .then((resp: any) => {
        setConsultores(resp.resultado);
      })
      .catch((err: any) => toast.warn(err));
    setLoading(false);
    setLoading(false);
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== 'onboarding'
    )!;
    setEtapasSelect(listEtapas);
    openDrawerReuniao(true);
    setEditando(true);
    setReuniao({
      ...reuniao,
      id: params.row.id,
      tbEtapaId: params.row.tbEtapaId,
      comandaId: params.row.comandaId,
      dtPrevista: dayjs.tz(params.row.dtPrevista),
      dtRealizadaInicio: dayjs.tz(params.row.dtRealizadaInicio),
      dtRealizadaFim: dayjs.tz(params.row.dtRealizadaFim),
      onboarding: params.row.onboarding,
      idConsultorResponsavel: params.row.idConsultorResponsavel,
      valorHora: params.row.valorHora,
      tempoTotal: params.row.tempoTotal,
      valorTotal: params.row.valorTotal,
    });
  };

  const openDrawerReuniao = (result: any) => {
    setReuniao(empty);
    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== 'onboarding'
    )!;
    const comandaEtapaList = comandaEtapas?.filter(
      (f) => f.idConsultorResponsavel! > 0 && !f.onboarding
    );

    const llistEtapas = listEtapas?.filter(
      (f) =>
        !comandaEtapaList?.some(
          (s) => s.tbEtapaId === f.id && s.idConsultorResponsavel! > 0
        )
    );
    setEtapasSelect(llistEtapas);

    const etapasComMetodologia = etapas?.map((e) => {
      return {
        id: e.id,
        nome: `${e.idMetodologiaNavigation?.nome
          ? e.idMetodologiaNavigation?.nome + ' | '
          : +' | '
          } ${e.nome}`,
      };
    });
    setEtapasSelect(etapasComMetodologia!);

    setOpen(result);
    if (!result) setEditando(false);
  };

  const HandleApagar = (id: any) => {
    const sub = reunioes.filter((f) => f.id !== id);
    setReunioes(sub);
  };

  const AdicionarNaLista = () => {
    setLoadingSave(true);

    if (validarReunião()) {
      toast.warn('Preencha todos os campos para continuar');
      setLoadingSave(false);
      return;
    }

    let horaInicio = parse(
      new Date(reuniao.dtRealizadaInicio!.toString()).toLocaleTimeString()
    );
    let horaFim = parse(
      new Date(reuniao.dtRealizadaFim!.toString()).toLocaleTimeString()
    );

    if (horaInicio >= horaFim) {
      toast.warn('Hora Início não pode ser maior que a hora fim!');
      setLoadingSave(false);
      return;
    }

    if (reuniao?.id === 0) {
      comandaEtapaActions
        .create(reuniao)
        .then((result) => {
          const newList = reunioes.concat(result);
          setReunioes(newList);
          openDrawerReuniao(false);
          setReuniao(empty);
        })
        .catch(() => { });
    } else {
      comandaEtapaActions
        .update(reuniao!.id!, reuniao)
        .then((result) => {
          const sub = reunioes.filter((f) => f.id !== result.id);
          setReunioes(sub.concat(result));
          setReuniao(empty);
          openDrawerReuniao(false);
          toast.success('Atualizado com sucesso');
        })
        .catch(() => { });
    }

    setLoadingSave(false);
  };

  const validarReunião = () => {
    if (reuniao.dtPrevista === undefined) return true;
    if (reuniao.dtRealizadaInicio === undefined) return true;
    if (reuniao.dtRealizadaFim === undefined) return true;
    if (reuniao.tbEtapaId === undefined) return true;
    if (reuniao.idConsultorResponsavel === undefined) return true;
    if (reuniao.valorHora === undefined || reuniao.valorHora === 0) return true;
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <Typography variant="subtitle1" sx={{ padding: '10px' }}>
            Responsáveis pelas reuniões:
          </Typography>
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) =>
                `${row?.tbEtapaId}_${row?.comandaId}_${Math.floor(
                  Math.random() * 20
                )}`
              }
              rows={reunioes ?? []}
              onRowClick={handleRowClick}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: 'absolute',
              right: '6%',
              zIndex: '30',
              marginTop: '-110px',
              left: '8%'
            }}
          >
            <ButtonStato
              style={{
                padding: '0px',
                height: '35px',
                minWidth: '35px',
              }}
              onClick={() => openDrawerReuniao(true)}
              variant="contained"
            >
              +
            </ButtonStato>
          </GridItemCadastroBtnSalvar>

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerReuniao(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerReuniao(false)}>
                    close
                  </IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5" sx={{ paddingBottom: '20px' }}>
                    {'Agendar próxima reunião'}
                  </Typography>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Etapas</InputLabel>
                <SelectStato
                  required
                  label="Etapas"
                  disabled={editando}
                  value={reuniao.tbEtapaId}
                  onChange={(event: any) => {
                    setReuniao({
                      ...reuniao,
                      tbEtapaId: event.target.value,
                      comandaId: idComanda,
                    });
                  }}
                >
                  {etapasSelect?.map((etapa: IEtapa, index: number) => {
                    return (
                      <MenuItem value={etapa.id} key={index}>
                        {etapa.nome}
                      </MenuItem>
                    );
                  })}
                </SelectStato>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    clearButtonLabel: 'Empty',
                    todayButtonLabel: 'Now',
                  }}
                >
                  <DesktopDatePicker
                    value={reuniao.dtPrevista}
                    label="Data Prevista"
                    format="DD/MM/YYYY"
                    timezone={'UTC'}
                    defaultValue={reuniao.dtPrevista}
                    onChange={(value: any) => {
                      setReuniao({ ...reuniao, dtPrevista: value });
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'MobileTimePicker',
                      'MobileTimePicker',
                      'MobileTimePicker',
                    ]}
                  >
                    <DemoItem label={'Hora Início'}>
                      <TimePicker
                        sx={{ width: '100% !important' }}
                        views={['hours', 'minutes']}
                        format='HH:mm'
                        label='Hora Início'
                        ampm={false}
                        ampmInClock={false}
                        value={reuniao.dtRealizadaInicio ? dayjs(reuniao.dtRealizadaInicio) : null}
                        onChange={(newValue) => {
                          const localDate = newValue ? dayjs.tz(newValue, 'America/Sao_Paulo') : null;
                          setReuniao({ ...reuniao, dtRealizadaInicio: (localDate ? localDate.format() : null) });
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            variant: 'outlined',
                          }
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'MobileTimePicker',
                      'MobileTimePicker',
                      'MobileTimePicker',
                    ]}
                  >
                    <DemoItem label={'Hora Fim'}>
                      <TimePicker
                        sx={{ width: '100% !important' }}
                        views={['hours', 'minutes']}
                        format='HH:mm'
                        label='Hora Fim'
                        ampm={false}
                        ampmInClock={false}
                        value={reuniao.dtRealizadaFim ? dayjs(reuniao.dtRealizadaFim) : null}
                        onChange={(newValue) => {
                          const localDate = newValue ? dayjs.tz(newValue, 'America/Sao_Paulo') : null;
                          setReuniao({ ...reuniao, dtRealizadaFim: (localDate ? localDate.format() : null) });
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            variant: 'outlined',
                          }
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <InputLabel id="demo-simple-select-label">
                  Consultores
                </InputLabel>
                <SelectStato
                  required
                  label="Consultores"
                  value={reuniao.idConsultorResponsavel}
                  onChange={(event: any) => {
                    setReuniao({
                      ...reuniao,
                      idConsultorResponsavel: event.target.value,
                      valorHora: consultores.find(
                        (f) => f.id === event.target.value
                      )?.valorHoraB2C,
                    });
                    setValorHora(
                      consultores
                        .find((f) => f.id === event.target.value)
                        ?.valorHoraB2C?.toString()
                    );
                  }}
                >
                  {consultores?.map((consultor: IConsultor, index: number) => {
                    return (
                      <MenuItem value={consultor.id} key={index}>
                        {consultor.nome}
                      </MenuItem>
                    );
                  })}
                </SelectStato>
              </FormControl>

              <FormControl
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <NumericFormat
                  value={reuniao?.valorHora}
                  onChange={(e) => console.debug(e.target.value)}
                  onValueChange={(e) =>
                    setReuniao({ ...reuniao, valorHora: parseFloat(e.value!) })
                  }
                  prefix="R$"
                  thousandSeparator="."
                  decimalSeparator=","
                  label="Valor Hora"
                  customInput={TextFieldStato}
                  valueIsNumericString
                  allowLeadingZeros={false}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </FormControl>

              {loadingSave && <LinearProgress />}

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant="contained" onClick={AdicionarNaLista}>
                  Agendar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default ReuniaoComponent;
