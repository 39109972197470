import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IEmpresa } from '../../../commons/genericTypes';
import { possuiPermissao } from '../../../commons/validaPermissao';
import { useEmpresasActions } from '../../../states/empresas/empresa.actions';
import { useRecoilValue } from 'recoil';
import { ButtonStato, GridContainer, GridTopPage, IconTituloStato, TextFieldStato } from '../../../commons/styleds';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../helpers/history';

const empty: IEmpresa = {
  id: 0,
  cnpj: "",
  razaosocial: "",
  nomefantasia: "",
  matriz: false,
  ativo: false
};


const Empresa: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const empresaActions = useEmpresasActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [empresaTemp, setEmpresaTemp] = useState<IEmpresa>(empty);
  const [selectedEmpresas, setSelectedEmpresas] = useState<
    IEmpresa[] | undefined
  >(undefined);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 200,
    },
    {
      field: 'nomefantasia',
      headerName: 'Nome Fantasia',
      width: 220,
    },
    {
      field: 'razaosocial',
      headerName: 'Razão Social',
      width: 220,
    },
    {
      field: 'action',
      headerName: '',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEmpresa;
        return <Icon onClick={() => handleClickOpen(obj)} style={{ color: "gray", cursor: "pointer" }}>delete</Icon>;
      },
    },
  ];


  const deleteValue = () => {
    empresaActions.delete(empresaTemp.id!).then((resp: IEmpresa[]) => {
      const sub = empresas.filter((f) => f.id !== empresaTemp.id);
      setEmpresas(sub);
      atualizarFormulario(empty);
    }).catch((err: any) => toast.warn(err));

    methods.reset(empresaTemp, { keepDefaultValues: true });
    setIdAtualizar(empresaTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const HandleAtivoInativo = (usuario: any) => {
    usuario.ativo = usuario.ativo ? false : true;
    AtualizarUsuario(usuario)
    atualizarFormulario(empty);
  };

  const AtualizarUsuario = (data: any) => {
    empresaActions
      .update(data.id, data)
      .then((result: IEmpresa) => {
        const sub = empresas.filter((f) => f.id !== result.id);
        setEmpresas(sub.concat(result));
        atualizarFormulario(empty);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };


  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.id > 0)
      AtualizarUsuario(data);
    else
      empresaActions
        .create(data)
        .then((result: IEmpresa) => {
          setEmpresas(empresas.concat(result));
          atualizarFormulario(empty);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });

    setLoadingSave(false);
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    empresaActions
      .list()
      .then((resp: IEmpresa[]) => setEmpresas(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setEmpresaTemp(obj)
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setEmpresaTemp(empty);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: "flex-end"
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant='h5'>Empresas</Typography>
        </Grid>

      </GridTopPage>
      {/* <Menu /> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Tem certeza que dejesa excluir esse item ?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color='error' onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato
                id='formArea'
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <h3>
                  {idAtualizar
                    ? `Atualizar - ID: ${idAtualizar}`
                    : 'Novo Cadastro'}
                </h3>
                <Controller
                  control={methods.control}
                  name='cnpj'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='CNPJ'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={methods.control}
                  name='nomefantasia'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Nome Fantasia'
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={methods.control}
                  name='razaosocial'
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <FormInput
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        label='Razão social'
                      />
                    </FormControl>
                  )}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name='ativo'
                    render={({ field }) => (
                      <FormControlLabelStato
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                          //defaultChecked={true}
                          />
                        }
                        label='Ativo'
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type='submit' variant='contained'>
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color='error'
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant='contained'
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={empresas}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage >
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Empresa;
