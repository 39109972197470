import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Alert,
  Drawer,
  Typography,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  DataGrid,
  GridEventListener,
  GridCellParams,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { IGraduacao } from '../../../../commons/genericTypes';
import { ButtonStato, GridContainer, GridContainerCenter, IconDrawer, IconTituloStato, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { possuiPermissao } from '../../../../commons/validaPermissao';
import { useGraduacaoActions } from '../../../../states/crm/contatoAuxiliares/graduacoes/graduacao.actions';
import ButtonAdd from '../ButtonAdd';

const empty: IGraduacao = {
  id: 0,
  tipo: "",
  nome: "",
  programa: "",
  graduado: false,
  descricao: ""
};

interface Props {
  novoUsuario: boolean
}

const GraduacaoComponent: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  const auth = useRecoilValue(authAtom);
  // const graduacaoActions = useGraduacaoActions();
  const graduacaoActions = useGraduacaoActions();


  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [mes, setMes] = useState<string>("");
  const [ano, setAno] = useState<string>("");
  const id: number = methods.watch('id');
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const graduacoesWatch: IGraduacao[] = methods.watch('graduacao');
  const [graduacoes, setGraduacaos] = useState<IGraduacao[]>([]);
  const [listGraduacao, setListGraduacao] = useState<any[]>([
    { "id": 1, "nome": "Graduação, Pós, MBA e Especializações" },
    { "id": 2, "nome": "Outros cursos ou seminários/congressos" }
  ]);
  const [graduacao, setGraduacao] = useState<IGraduacao>();
  const [selectedGraduacaos, setSelectedGraduacaos] = useState<
    IGraduacao[] | undefined
  >(undefined);



  const columns: GridColDef[] = [
    {
      field: 'tipoFormacao',
      headerName: 'Tipo Formação',
      width: 250,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return listGraduacao?.find((f) => f.id === obj.tipo)?.nome;
      },
    },
    {
      field: 'curso',
      headerName: 'Curso',
      width: 150,
    },
    {
      field: 'instituicao',
      headerName: 'Instituição',
      width: 120,
    },
    {
      field: 'cursando',
      headerName: 'Cursando',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return <Checkbox
          disabled={true}
          checked={obj.graduado}
          inputProps={{ 'aria-label': 'controlled' }}
        />;
      },
    },
    {
      field: 'graduacaoEm',
      headerName: 'Graduação Em',
      width: 150,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return <Icon
          sx={{ cursor: 'pointer' }}
          onClick={() => HandleApagar(obj.id!)}
        >
          {' '}
          <DeleteIcon />{' '}
        </Icon>
      },
    },
  ];

  const HandleAtivoInativo = (usuario: any) => {
    usuario.ativo = usuario.ativo ? false : true;

    atualizarFormulario(empty);
  };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    setLoadingSave(false);
  };

  useEffect(() => {
    if (!possuiPermissao(auth, ['configuracoes sistema'])) {
      toast.warn('Você não possui permissão para acessar esse módulo');
      history.push('/');
    }

    if (graduacoesWatch?.length > 0)
      setGraduacaos(graduacoesWatch);


    setLoading(false);
  }, []);

  useEffect(() => {
    if (id) {
      graduacaoActions
        .list(false, `?contatoId=${id}`)
        .then((resp: IGraduacao[]) => setGraduacaos(resp))
        .catch((err: any) => toast.warn(err));

      setLoading(false);
    }

  }, [id]);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const HandleSetFormcao = (result: any) => {
    setGraduacao({ ...graduacao, tipo: result.target.value });
  };

  const openDrawerGraduacao = (result: any) => {
    setGraduacao(empty);
    setOpen(result);
  };

  const HandleApagar = (id: any) => {
    graduacaoActions.delete(id).then((data) => {
      const sub = graduacoes.filter((f) => f.id !== id);
      setGraduacaos(sub);
      methods.setValue('graduacao', graduacoes!);
    }).catch((erro) => {

    });
  };

  const AdicionarNaLista = () => {
    let idContato = 0;
    if (Number(id) > 0) {
      graduacao!.IdContato = Number(id);
    }

    graduacaoActions.create(graduacao!).then((data: IGraduacao) => {
      const newList = graduacoes.concat(data!);
      setGraduacaos(newList);
      openDrawerGraduacao(false);
    })

  };

  return <>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          height: '250px',
          width: '100%',
        }}
      >
        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
            onRowClick={handleRowClick}
            rows={graduacoes ?? []}
            columns={columns}
            loading={loading}
            sx={{
              backgroundColor: 'white',
              border: '1px solid black',
              borderRadius: '14px',
            }}
          />
        </ThemeProvider>

        <ButtonAdd onClick={() => openDrawerGraduacao(true)} />

        <Drawer
          style={{ minWidth: '250px' }}
          anchor={'right'}
          open={open}
          onClose={() => openDrawerGraduacao(false)}
        >
          <Grid item xs={12} md={12} style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}>

            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: "center" }}>
              <Grid item xs={2} md={2}>
                <IconDrawer onClick={() => openDrawerGraduacao(false)}>close</IconDrawer>
              </Grid>
              <Grid item xs={10} md={10}>
                <Typography variant="h5">{'Adicionar Graduação na Lista'}</Typography>
              </Grid>
            </Grid>

            <FormControl required fullWidth variant='outlined' sx={{ marginTop: "20px" }}>
              <InputLabel id='demo-simple-select-label'>
                Tipo Formação
              </InputLabel>
              <SelectStato onChange={HandleSetFormcao} required label='Tipo Formação'>
                {listGraduacao &&
                  listGraduacao!.map(
                    (graduacao, index: number) => (
                      <MenuItem value={graduacao.id} key={index}>
                        {graduacao.nome}
                      </MenuItem>
                    )
                  )}
              </SelectStato>
            </FormControl>

            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
              <TextFieldStato
                id={"GraduacaoInput"}
                type="text"
                label="Curso"
                value={graduacao?.nome}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGraduacao({ ...graduacao, nome: event.target.value });
                }}
              />
            </FormControl>

            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
              <TextFieldStato
                id={"GraduacaoInput"}
                type="text"
                label="Instituição"
                value={graduacao?.programa}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGraduacao({ ...graduacao, programa: event.target.value });
                }}
              />
            </FormControl>

            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
              {/* <TextFieldStato
                                id={"GraduacaoInput"}
                                type="text"
                                label="Cursando"
                                value={graduacao?.cursando}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setGraduacao({ ...graduacao, cursando: event.target.value });
                                }}
                            /> */}
              <Controller
                control={methods.control}
                name='cursando'
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setGraduacao({ ...graduacao, graduado: event.target.checked });
                        }}
                        checked={graduacao?.graduado}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label='Cursando'
                  />
                )}
              />
            </FormControl>

            <InputLabel sx={{ 'marginTop': '20px' }}>Graduação em:</InputLabel>
            <Grid item xs={12} md={12} style={{ display: 'flex' }}>
              <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '10px', 'marginRight': '10px' }}>
                  <TextFieldStato
                    id={"GraduacaoInput"}
                    type="number"
                    label="Mês"
                    value={graduacao?.mesGraduacao}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGraduacao({
                        ...graduacao,
                        mesGraduacao: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '10px' }}>
                  <TextFieldStato
                    id={"GraduacaoInput"}
                    type="number"
                    label="Ano"
                    value={graduacao?.anoGraduacao}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGraduacao({
                        ...graduacao,
                        anoGraduacao: Number(event.target.value)
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>


            <FormControl required fullWidth variant="outlined" sx={{ 'marginTop': '20px' }}>
              <TextFieldStato
                sx={{ height: 90 }}
                id={"GraduacaoInput"}
                type="text"
                label="Descrição do Curso ou Certificações"
                value={graduacao?.descricao}
                multiline
                maxRows={4}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGraduacao({ ...graduacao, descricao: event.target.value });
                }}
              />
            </FormControl>

            <GridItemCadastroBtnSalvar item xs={11} md={11} sx={{
              position: "fixed",
              right: "0px",
              bottom: "0px"
            }}>
              <ButtonStato variant="contained" onClick={AdicionarNaLista}>Adicionar</ButtonStato>
            </GridItemCadastroBtnSalvar>
          </Grid>
        </Drawer >
      </Box >
    </Grid >
  </>;
}

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default GraduacaoComponent;
