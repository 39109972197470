import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Grid,
  Checkbox,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldStato, SelectStato } from '../../../../../commons/styleds';
import {
  NivelHierarquico,
  TipoConsultor,
} from '../../../../../commons/enums';
import React from 'react';
import { styled } from 'styled-components';
import { NumericFormat } from 'react-number-format';

const ConsultorStepper2: React.FC = () => {
  const methods = useFormContext();
  const grupoId = methods.watch('idGrupo');
  const tipoConsultorB2b = methods.watch('tipoConsultorB2B');
  const tipoConsultorB2c = methods.watch('tipoConsultorB2C');
  const consultorTipos = methods.watch('consultorTipos');

  const onChangeConsultorTipos = (checked: string, tipo: string) => {
    if (checked === 'false')
      methods.setValue('consultorTipos', [...consultorTipos, { tipo: tipo }]);
    else
      methods.setValue('consultorTipos', [...consultorTipos.filter((c: any) => c.tipo !== tipo)])
  };

  return (
    <>
      <Controller
        control={methods.control}
        name='celular'
        render={({ field: { ref, ...field } }) => (
          <FormControl
            id='celular'
            fullWidth
            variant='outlined'
            sx={{ marginTop: '20px' }}
          >
            <TextFieldStato
              {...field}
              type='tel'
              inputRef={ref}
              value={field.value}
              id='celular'
              label='Celular'
            />
          </FormControl>
        )}
      />

      <GridItemCadastro item xs={12} md={12}>
        <Controller
          control={methods.control}
          name='nivelHierarquico'
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginTop: '20px' }}>
              <InputLabel id='demo-simple-select-label'>
                Nível Hierarquico
              </InputLabel>
              <SelectStato {...field} label='Nível Hierarquico'>
                {Object.keys(NivelHierarquico).map(
                  (nivel: string, index: number) => (
                    <MenuItem value={nivel} key={index}>
                      {NivelHierarquico[nivel as keyof typeof NivelHierarquico]}
                    </MenuItem>
                  )
                )}
              </SelectStato>
            </FormControl>
          )}
        />
      </GridItemCadastro>

      <GridItemCadastro container item xs={12} md={12}>
        <Grid item xs={12}>
          <Typography variant='body1'>Modelo comercial:</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <Controller
            control={methods.control}
            name='tipoConsultorB2C'
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='B2C'
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Controller
            control={methods.control}
            name='tipoConsultorB2B'
            render={({ field }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='B2B'
              />
            )}
          />
        </Grid>
      </GridItemCadastro>

      <GridItemCadastro container item xs={12} sm={12} md={12}>
        {tipoConsultorB2c && (
          <>
            <Grid item xs={12} sm={6} md={6} sx={{ padding: '5px' }}>
              <Controller
                control={methods.control}
                name='valorHoraB2C'
                render={({ field: { ref, ...field } }) => (
                  <FormControl required fullWidth variant='outlined'>
                    <NumericFormat
                      {...field}
                      onChange={(e) => console.debug(e.target.value)}
                      onValueChange={(e) => field.onChange(e.value)}
                      prefix='R$'
                      thousandSeparator='.'
                      decimalSeparator=','
                      label='Valor hora B2C'
                      customInput={TextFieldStato}
                      valueIsNumericString
                      allowLeadingZeros={false}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}

        {tipoConsultorB2b && (
          <>
            <Grid item xs={12} sm={6} md={6} sx={{ padding: '5px' }}>
              <Controller
                control={methods.control}
                name='valorHoraB2B'
                render={({ field: { ref, ...field } }) => (
                  <FormControl required fullWidth variant='outlined'>
                    <NumericFormat
                      {...field}
                      onChange={(e) => console.debug(e.target.value)}
                      onValueChange={(e) => field.onChange(e.value)}
                      prefix='R$'
                      thousandSeparator='.'
                      decimalSeparator=','
                      label='Valor hora B2B'
                      customInput={TextFieldStato}
                      valueIsNumericString
                      allowLeadingZeros={false}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}
      </GridItemCadastro>

      <GridItemCadastro container item xs={12} md={12}>
        <Grid item xs={12}>
          <Typography variant='body1'>Tipos Consultor:</Typography>
        </Grid>
        {Object.keys(TipoConsultor).map((tipo: string) => (
          <>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={
                      consultorTipos?.find((c: any) => c.tipo === tipo) !==
                      undefined
                    }
                    checked={
                      consultorTipos?.find((c: any) => c.tipo === tipo) !==
                      undefined
                    }
                    onChange={(e) =>
                      onChangeConsultorTipos(e.target.value, tipo)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={tipo}
              />
            </Grid>
          </>
        ))}
      </GridItemCadastro>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default ConsultorStepper2;
