import { Controller, useFormContext } from 'react-hook-form';
import { GridItemCadastro, GridItemLinhaCadastro } from '..';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {
  ButtonStato,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  IConsultor,
  IEtapa,
  IStatus,
  IUsuario,
} from '../../../../commons/genericTypes';
import { useEffect, useState } from 'react';
import { useStatusActions } from '../../../../states/config/Programas/status/status.actions';
import { useMountEffect } from '../../../../commons/utils';
import { useEtapaActions } from '../../../../states/config/Programas/etapas/etapa.actions';
import { toast } from 'react-toastify';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { NivelHierarquico } from '../../../../commons/enums';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { NumericFormat } from 'react-number-format';
import { useConsultorActions } from '../../../../states/config/CadastroUsuarios/consultores/consultor.actions';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

interface IPlanoCampos {
  novoUsuario: boolean;
  openDrawer: Function;
  defaultPrazoPlano?: number;
}

const PlanoCampos: React.FC<IPlanoCampos> = (props) => {
  const { openDrawer, defaultPrazoPlano } = props;
  const methods = useFormContext();
  const dataInicio = methods.watch('dataInicio');
  const statusActions = useStatusActions();
  const etapaActions = useEtapaActions();
  const consultorActions = useConsultorActions();

  const [status, setStatus] = useState<IStatus[]>([]);
  const [etapas, setEtapas] = useState<IEtapa[] | undefined>(undefined);
  const [onboarding, setOnboarding] = useState<boolean>(false);
  const [etapasSelect, setEtapasSelect] = useState<IEtapa[] | undefined>(
    undefined
  );

  const [consultoresOnboarding, setConsultoresOnboarding] = useState<
    IConsultor[]
  >([]);

  const getStatusDb = () => {
    statusActions
      .list()
      .then((resp) => setStatus(resp))
      .catch((err) => toast.warn(err));
  };

  const getEtapasDb = () => {
    etapaActions
      .list()
      .then((resp: IEtapa[]) => setEtapas(resp))
      .catch((err) => toast.warn(err));
  };

  const onMount = () => {
    getStatusDb();
    getEtapasDb();
  };

  useMountEffect(onMount);

  useEffect(() => {
    const etapasComMetodologia = etapas?.map((e) => {
      return {
        id: e.id,
        nome: `${e.idMetodologiaNavigation?.nome
          ? e.idMetodologiaNavigation?.nome + ' | '
          : +' | '
          } ${e.nome}`,
      };
    });
    setEtapasSelect(etapasComMetodologia);

    consultorActions
      .list()
      .then((resp: any) => {
        setConsultoresOnboarding(resp.resultado);
      })
      .catch((err: any) => toast.warn(err));
  }, [etapas]);

  useEffect(() => {
    if (dataInicio && defaultPrazoPlano !== undefined) {
      const newDataFim = dayjs(dataInicio).add(defaultPrazoPlano, 'month');
      methods.setValue('dataFim', newDataFim);
    }
  }, [dataInicio, defaultPrazoPlano]);

  const handleChange = (event: any) => {
    setOnboarding(event.target.value === 'true');
  };

  return (
    <>
      <Grid item xs={12} sx={{ flexDirection: 'column', marginBottom: '15px' }}>
        <Divider />
      </Grid>

      <GridItemLinhaCadastro item xs={12} md={12}>
        <GridItemCadastro item xs={12} md={6}>
          <Controller
            control={methods.control}
            name='plano'
            render={({ field }) => (
              <FormControl required fullWidth variant='outlined'>
                <TextFieldStato {...field} type='plano' label='Plano' />
              </FormControl>
            )}
          />
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={3}>
          <ButtonStato variant='contained' onClick={() => openDrawer(true)}>
            {'Selecionar um Template'}
          </ButtonStato>
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={4}></GridItemCadastro>
      </GridItemLinhaCadastro>

      <GridItemLinhaCadastro item xs={12} md={12} sx={{ display: 'flex' }}>
        <GridItemCadastro item xs={12} md={7} lg={7}>
          <Controller
            control={methods.control}
            name='etapas'
            render={({ field: { ref, onChange, value, ...field } }) => (
              <FormControl required fullWidth variant='outlined'>
                {etapasSelect && (
                  <MultiSelectNome
                    opcoes={etapasSelect as IEtapa[]}
                    setValor={onChange}
                    valor={value || []}
                    label='Etapas/Reuniões'
                  />
                )}
              </FormControl>
            )}
          />
        </GridItemCadastro>
      </GridItemLinhaCadastro>

      <GridItemLinhaCadastro
        item
        xs={12}
        md={12}
        sx={{ display: 'flex', marginBottom: '10px' }}
      >
        <GridItemCadastro item xs={6} md={3}>
          <Controller
            control={methods.control}
            name='dataInicio'
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  clearButtonLabel: 'Empty',
                  todayButtonLabel: 'Now',
                }}
              >
                <DesktopDatePicker
                  {...field}
                  label='Data Início'
                  format='DD/MM/YYYY'
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </GridItemCadastro>

        <GridItemCadastro item xs={6} md={3}>
          <Controller
            control={methods.control}
            name='dataFim'
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  clearButtonLabel: 'Empty',
                  todayButtonLabel: 'Now',
                }}
              >
                <DesktopDatePicker
                  {...field}
                  label='Data Término'
                  format='DD/MM/YYYY'
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </GridItemCadastro>

        <GridItemCadastro item xs={12} md={6}>
          <Controller
            control={methods.control}
            name='status'
            render={({ field }) => (
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                <SelectStato {...field} required label='Status'>
                  {status.map((statu: IStatus, index: number) => (
                    <MenuItem value={statu.id} key={index}>
                      {statu.nome}
                    </MenuItem>
                  ))}
                </SelectStato>
              </FormControl>
            )}
          />
        </GridItemCadastro>
      </GridItemLinhaCadastro>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ flexDirection: 'column', marginBottom: '15px' }}
      >
        <Divider />
      </Grid>

      <GridItemLinhaCadastro
        item
        xs={12}
        md={12}
        sx={{ display: 'flex', marginBottom: '10px' }}
      >
        <GridItemCadastro item xs={12} md={12} lg={12}>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Onboarding?</FormLabel>
              <RadioGroup
                aria-label="onboarding"
                name="onboarding"
                value={onboarding.toString()}
                onChange={handleChange}
                sx={{ display: 'flex', flexDirection: 'row !important' }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Sim" />
                <FormControlLabel value="false" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {onboarding && (
            <>
              <Grid item xs={12} md={9} lg={9}>
                <Controller
                  control={methods.control}
                  name='idConsultorOnboarding'
                  render={({ field }) => (
                    <FormControl required fullWidth variant='outlined'>
                      <InputLabel id='demo-simple-select-label'>
                        Consultor Onboarding
                      </InputLabel>
                      <SelectStato {...field} required label='Consultor Onboarding'>
                        {consultoresOnboarding &&
                          consultoresOnboarding!.map(
                            (consultores: IConsultor, index: number) => (
                              <MenuItem value={consultores.id} key={index}>
                                {consultores.nome}
                              </MenuItem>
                            )
                          )}
                      </SelectStato>
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
        </GridItemCadastro>
      </GridItemLinhaCadastro>

      {onboarding && (
        <>
          <GridItemLinhaCadastro
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', marginBottom: '10px' }}
          >
            <GridItemCadastro item xs={6} md={3}>
              <Controller
                control={methods.control}
                name='dataPrevista'
                render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      {...field}
                      label='Data Prevista'
                      format='DD/MM/YYYY'
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </GridItemCadastro>

            <GridItemCadastro item xs={6} sm={2} md={3}>
              <Controller
                control={methods.control}
                name='dataRealizadaInicio'
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ width: '100% !important' }}
                      {...field}
                      views={['hours', 'minutes']}
                      format='HH:mm'
                      label='Hora Início'
                      ampm={false}
                      ampmInClock={false}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(newValue) => {
                        const localDate = newValue ? dayjs.tz(newValue, 'America/Sao_Paulo') : null;
                        field.onChange(localDate ? localDate.format() : null); // Formatar para string local
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          fullWidth: true,
                          variant: 'outlined',
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </GridItemCadastro>

            <GridItemCadastro item xs={6} sm={2} md={3}>
              <Controller
                control={methods.control}
                name='dataRealizadaFim'
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      sx={{ width: '100% !important' }}
                      {...field}
                      views={['hours', 'minutes']}
                      format='HH:mm'
                      label='Hora Fim'
                      ampm={false}
                      ampmInClock={false}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(newValue) => {
                        const localDate = newValue ? dayjs.tz(newValue, 'America/Sao_Paulo') : null;
                        field.onChange(localDate ? localDate.format() : null);
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          fullWidth: true,
                          variant: 'outlined',
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </GridItemCadastro>

            <GridItemCadastro item xs={6} sm={2} md={3}>
              <Controller
                control={methods.control}
                name='valorHora'
                render={({ field }) => (
                  <NumericFormat
                    value={field.value}
                    required
                    onChange={(e) => console.debug(e.target.value)}
                    onValueChange={(e) => field.onChange(e.value)}
                    prefix='R$'
                    thousandSeparator='.'
                    decimalSeparator=','
                    label='Valor Hora'
                    customInput={TextFieldStato}
                    valueIsNumericString
                    allowLeadingZeros={false}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                )}
              />
            </GridItemCadastro>
          </GridItemLinhaCadastro>
        </>
      )}

      <GridItemLinhaCadastro item xs={12} md={5}>
        <Controller
          control={methods.control}
          name='idConsultorMaster'
          render={({ field }) => (
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='consultor-master-select-label'>
                Consultor Responsável
              </InputLabel>
              <SelectStato {...field} label='Consultor Responsável'>
                {consultoresOnboarding &&
                  consultoresOnboarding!.map(
                    (consultores: IConsultor, index: number) => (
                      <MenuItem value={consultores.id} key={index}>
                        {consultores.nome}
                      </MenuItem>
                    )
                  )}
              </SelectStato>
            </FormControl>
          )}
        />
      </GridItemLinhaCadastro>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ flexDirection: 'column', marginTop: '5px' }}
      >
        <Divider />
      </Grid>
    </>
  );
};

export default PlanoCampos;
